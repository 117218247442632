<template>
  <div :class="[$style.banner, 'container']">
    <picture>
      <source :srcset="`/images/HomeBanner1-${locale}-m.webp`" media="(max-width: 767px)">
      <img :src="`/images/HomeBanner1-${locale}.webp`" alt="" data-aos="fade-top" data-aos-delay="0"/>
    </picture>
    <div v-if="false" :class="$style.download" data-aos="fade-top" data-aos-delay="300">
      <picture>
        <source srcset="/images/HomeBannerBcg-t.webp" media="(min-width: 768px) and (max-width: 1023px)">
        <source srcset="/images/HomeBannerBcg-m.webp" media="(max-width: 767px)">
        <img
          :class="$style.downloadBcg"
          src="/images/HomeBannerBcg.webp"
          alt=""
        />
      </picture>

      <div :class="$style.title">{{ t('home.browserApp.app') }}</div>
      <UiButton
        color="white"
        to="/downloads"
      >
        <template #label>
          {{ t('home.browserApp.button') }}
        </template>
      </UiButton>

      <img
        :class="$style.frame"
        src="/images/HomeBannerFrame.webp"
        alt=""
      />
    </div>
  </div>
</template>

<script setup lang="ts">
const { t, locale } = useI18n();
</script>

<style lang="scss" module>
  .banner {
    position: relative;
    display: flex;
    gap: 12px;

    @media (max-width: 1023px) {
      flex-direction: column;
    }

    > img,
    > picture img {
      // width: calc(100% - 33.08% - 22px);
      width: 100%;

      @media (max-width: 1023px) {
        width: 100%;
      }
    }
  }

  .download {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 24px;
    height: calc(100% - 9.3%);
    overflow: hidden;

    @media (min-width: 1024px) {
      position: absolute;
      bottom: 0;
      right: 16px;
      flex-shrink: 0;
      width: 33.08%;
    }

    @media (max-width: 1023px) {
      position: relative;
    }

    &Bcg {
      z-index: -1;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: top center;

      @media (max-width: 1023px) {
        object-position: center center;
      }
    }
  }

  .title {
    color: #FFF;
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    @media (min-width: 1024px) {
      margin-top: 46px;
      margin-bottom: 24px;
      font-size: 32px;
    }

    @media (max-width: 1023px) {
      margin-top: 32px;
      margin-bottom: 18px;
      font-size: 24px;
    }
  }

  .frame {
    margin-top: auto;
    width: 87.2%;

    @media (max-width: 1023px) {
      width: 297px;
    }
  }
</style>